<template>
  <van-pull-refresh class="deviceWorkerAll" v-model="refreshing" @refresh="onRefresh">
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <device-work-cell v-for="(item, index) in tableData" :data-info="item" @start="start"></device-work-cell>
    </van-list>
  </van-pull-refresh>
</template>

<script>

import DeviceWorkCell from '@views/shareAndDispatch/deviceView/deviceWorker/deviceWorkCell/deviceWorkCell'
import deviceWorkerMixin from '@views/shareAndDispatch/deviceView/deviceWorker/deviceWorkerMixin'

export default {
  name: 'deviceWorkerAll',
  components: { DeviceWorkCell },
  mixins: [deviceWorkerMixin],
  data() {
    return {
      queryType:11,
    }
  },
  methods: {

  }
}
</script>


<style lang="scss" scoped>

.deviceWorkerAll {
  width: 100%;
  height: 100%;
  overflow: scroll;
}


</style>